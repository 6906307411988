// **  Initial State
const initialState = {
    admin: { loading: true, admins: [], pagination: {} },
    adminInfo: {}
}

const manageAdminsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_ADMINS': 
            return {
                ...state,
                admin: action.data
            }
        case 'GET_ADMIN_INFO': 
            return {
                ...state,
                adminInfo: action.data
            }
        default: 
         return state
    }
}

export default manageAdminsReducer