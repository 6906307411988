// **  Initial State
const initialState = {
    importNfts: { loading: true, report: [], pagination: {} }
}

const manageImportReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_IMPORT_REPORTS': 
            return {
                ...state,
                importNfts: action.data
            }
        default: 
            return state
    }
}

export default manageImportReducer