// **  Initial State
const initialState = {
    subscriptionList: { loading: true, subscriptions: [], pagination: {} }
}

const SubscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SUBSCRIPTION_LIST': 
            return {
                ...state,
                subscriptionList: action.data
            }
        default:
            return state
    }
}

export default SubscriptionReducer