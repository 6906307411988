
// **  Initial State
const initialState = {
    listOfannouncement: [],
    announcementInfo: {}
}

const manageAnnouncementReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_ANNOUNCEMENT':
            return {
                ...state,
                listOfannouncement: action.data
            }
        case 'GET_ANNOUNCEMENT_BY_ID':
            return {
                ...state,
                announcementInfo: action.data
            }
        default:
            return state
    }
}

export default manageAnnouncementReducer