// **  Initial State
const initialState = {
  users: { loading: true, users: [], pagination: {} },
  userInfo: null,
  userKyc: null,
  artworkListOfArtist: null,
  trendingArtist: null,
  accountDetail: null,
  artistExportArtwork:null
}

const manageUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ARTIST':
      return {
        ...state,
        users: action.data
      }
    case 'GET_ARTIST_INFO':
      return {
        ...state,
        userInfo: action.data
      }
    case 'GET_ARTWORK_LIST_OF_ARTIST':
      return {
        ...state,
        artworkListOfArtist: action.data
      }
    case 'GET_TRENDING_ARTIST':
      return {
        ...state,
        trendingArtist: action.data
      }
    case 'GET_ARTIST_KYC_DETAILS':
      return {
        ...state,
        userKyc: action.data
      }
    case 'GET_BANK_DETAILS_ARTIST':
      return {
        ...state,
        accountDetail: action.data
      }
    case 'GET_EXPORT_ARTWORK_ARTIST':
      return {
        ...state,
        artistExportArtwork: action.data
      }
    case 'GET_WALLET_HISTORY':
      return {
        ...state,
        walletHistory: action.data
      }
    default:
      return state
  }
}

export default manageUsersReducer
