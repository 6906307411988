// **  Initial State
const initialState = {
  artwork: { loading: true, artwork: [], pagination: {} },
  artworkTransaction: null,
  putItemOnSale: null,
  trendingArts: null,
  ItemforManualTransfer: null,
  searchArtwork: null,
  burnNfts: null
}

const artworkReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_ARTWORK':
      return {
        ...state,
        artwork: action.data
      }
    case 'GET_ARTWORK_TRANSACTION_HISTORY':
      return {
        ...state,
        artworkTransaction: action.data
      }
    case 'SET_VALUE_PUT_ITEM_ON_SALE':
      return {
        ...state,
        putItemOnSale: action.data
      }
    case 'SET_VALUE_FOR_MANUAL_TRANSFER':
      return {
        ...state,
        ItemforManualTransfer: action.data
      }
    case 'GET_TRENDING_ARTWORK':
      return {
        ...state,
        trendingArts: action.data
      }
    case 'GET_NFTS_BY_OWNERS_ID':
      return {
        ...state,
        searchArtwork: action.data
      }
    case 'GET_ALL_BURN_NFTS':
      return {
        ...state,
        burnNfts: action.data
      }
    default:
      return state
  }
}

export default artworkReducer
