
// **  Initial State
const initialState = {
  PaymentHistory: null,
  paymentDetail:null
}

const paymentHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PAYMENT_TRANSACTION':
      return {
        ...state,
        PaymentHistory: action.data
      }
    case 'GET_PAYMENT_DETAIL':
      return {
        ...state,
        paymentDetail: action.data
      }
    default:
      return state
  }
}

export default paymentHistoryReducer
