const initialState = {
    collections: { loading: true, collections: [], pagination: {} },
    collectionInfo: {}
}

const manageCollectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_COLLECTION': 
            return {
                ...state,
                collections: action.data
            }
        case 'GET_COLLECTION_INFO': 
            return {
                ...state,
                collectionInfo: action.data
            }
        default: 
            return state
    } 
}

export default manageCollectionReducer