// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import artist from './artist'
import artwork from './artwork'
import exhibition from './exhibition'
import blockchainArtwork from './blockchainArtwork'
import client from './client'
import loading from './loading'
import dashboard from './dashboard'
import withdraw from './withdraw'
import exports from './export'
import imports from './import'
import kyc from './kyc'
import paymentHistory from './paymengHistory'
import subscription from './subscription'
import admin from './admin'
import announcement from './announcement'
import collection from './collection'

const rootReducer = combineReducers({
  auth,
  artist,
  navbar,
  layout,
  artwork,
  exhibition,
  blockchainArtwork,
  client,
  loading,
  dashboard,
  withdraw,
  exports,
  imports,
  kyc,
  paymentHistory,
  subscription,
  admin,
  announcement,
  collection
})

export default rootReducer
