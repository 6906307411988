
// **  Initial State
const initialState = {
    clients: null
  }
  
  const clientReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_CLIENT_LIST':
        return {
          ...state,
          client: action.data
        }
      default:
        return state
    }
  }
  
  export default clientReducer
  