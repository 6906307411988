// **  Initial State
const initialState = {
    blockchainArtwork: null
}

const blockchainArtwork = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_BLOCKCHAIN_ACTIVITY':
            return {
                ...state,
                blockchainArtwork: action.data
            }
        default:
            return state
    }
}

export default blockchainArtwork
