// **  Initial State
const initialState = {
    kycList: { loading: true, users: [], pagination: {} }
}

const manageKycReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_KYC_SUBMISSION_LIST':
            return {
                ...state,
                kycList: action.data
            }
        default :
            return state
    }
}

export default manageKycReducer